






































import {
  BootstrapButton,
  BootstrapImage,
  BootstrapImageProps,
  BootstrapLink,
  Link
} from '@movecloser/ui-core'
import { Component, Prop } from 'vue-property-decorator'

import { AbstractModuleUiPresentation } from '../../_abstract'

/**
 * Presentational component for the `ContactPersonModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<ContactPersonModuleUiPresentation>({
  name: 'ContactPersonModuleUiPresentation',
  components: {
    BootstrapButton,
    BootstrapImage,
    BootstrapLink,
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../atoms/UiHeading/UiHeading.vue'
    )
  }
})
export class ContactPersonModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * Array of less important links to render under the main CTA.
   */
  @Prop({ type: Array, required: false })
  public readonly bottomLinks?: Link[]

  /**
   * Person's company.
   */
  @Prop({ type: String, required: false })
  public readonly company?: string

  /**
   * Person's image (profile photo).
   */
  @Prop({ type: Object, required: false })
  public readonly image?: BootstrapImageProps

  /**
   * Link that should be attached to the main CTA button.
   */
  @Prop({ type: Object, required: false })
  public readonly mainLink?: Link

  /**
   * Person's name.
   */
  @Prop({ type: String, required: true })
  public readonly name!: string

  /**
   * Person's job role.
   */
  @Prop({ type: String, required: false })
  public readonly role?: string

  /**
   * Person's job role.
   */
  @Prop({ type: String, required: false })
  public readonly region?: string

  /**
   * Determines whether the component has been provided with the correct `bottomLinks` prop.
   */
  public get hasBottomLinks (): boolean {
    return typeof this.bottomLinks !== 'undefined' &&
      Array.isArray(this.bottomLinks) &&
      this.bottomLinks.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `company` prop.
   */
  public get hasCompany (): boolean {
    return typeof this.company === 'string' && this.company.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `mainLink` prop.
   */
  public get hasMainLink (): boolean {
    if (!this.mainLink) {
      return false
    }

    return typeof this.mainLink !== 'undefined' && typeof this.mainLink.target !== 'undefined'
  }

  /**
   * Determines whether the component has been provided with the correct `name` prop.
   */
  public get hasName (): boolean {
    return typeof this.name === 'string' && this.name.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `role` prop.
   */
  public get hasRole (): boolean {
    return typeof this.role === 'string' && this.role.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `region` prop.
   */
  public get hasRegion (): boolean {
    return typeof this.region === 'string' && this.region.length > 0
  }
}

export default ContactPersonModuleUiPresentation
