








import { BootstrapImageProps } from '@movecloser/ui-core'
import { Component } from 'vue-property-decorator'

import { isRelated } from '../../../../services'
import { log } from '../../../../support'

import { AbstractModuleUi } from '../../_abstract'

import { ContactPersonModule } from '../ContactPerson.contracts'

/**
 * Container component for the `ContactPersonModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<ContactPersonModuleUi>({
  name: 'ContactPersonModuleUi',
  components: {
    ContactPersonModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/ContactPerson" */
      './ContactPerson.ui.presentation.vue'
    )
  }
})
export class ContactPersonModuleUi extends AbstractModuleUi<ContactPersonModule> {
  /**
   * Person's image from `this.module.content` with all related data already resolved and ready to use.
   */
  public image: BootstrapImageProps | null = null

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await this.fetchPersonImage()
  }

  /**
   * Fetches person's image from the `RelatedService`.
   */
  private async fetchPersonImage (): Promise<void> {
    const unresolvedImage = this.data.content.image

    if (!isRelated(unresolvedImage)) {
      return
    }

    try {
      this.image = await this.fetchImage(unresolvedImage)
    } catch (error) {
      const message: string =
        'ContactPersonModuleUi.fetchPersonsImage(): Failed to fetch the related image from the [RelatedService]!'
      log(message, 'error')
    }
  }
}

export default ContactPersonModuleUi
